@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font family */
}

body {
  font-family: 'Poppins', sans-serif;
}

label {
  font-size: 1rem;
}

input.form-control,
input.form-control:focus {
  background: #F0F0FF;
}

.error {
  position: absolute;
  color: red;
  font-size: .6rem;
}